import { Component } from '@angular/core';
import { ActivatedRoute, ActivationStart, NavigationEnd, Router } from '@angular/router';
import { UserService } from './services/user.service';
import { FeatureFlagsService } from './services/feature-flags.service';
import { ROUTE_APPT, ROUTE_TASK, ROUTE_VACCINE, ROUTE_VIEW, ROUTE_VISIT } from '@string';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { GoogleTagsService } from './services/google-tags.service';
import { environment } from '@env/environment';
import { register } from 'swiper/element/bundle';
register();
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'patient-portal';
  public loaded = false;
  public featuresInit = false;
  public showNpsRating: boolean = false;
  public initalCheckNpsCompleted: boolean = false;
  public checkingNpsRating: boolean = false;
  public npsOptions: any;

  constructor(public user: UserService, public router: Router, private featureFlagService: FeatureFlagsService, private gtm:GoogleTagManagerService, private gtmService:GoogleTagsService, private route: ActivatedRoute) {
    this.router.events.subscribe(events => {
      if (events instanceof NavigationEnd || events instanceof ActivationStart) {
        let isPatientTask = false;
        if (this.router.url.includes(ROUTE_TASK) || this.router.url.includes(ROUTE_VACCINE) || this.router.url.includes(`/${ROUTE_VIEW}`) || this.router.url.includes(ROUTE_VISIT)) { 
          this.user.setPatientTask(true);
          isPatientTask = true;
        }
        if (!this.loaded) {
          this.user.getUpdateInfo('*').subscribe(data => {
            console.log('getUpdateInfo appcomponent', data, this.route.snapshot.queryParams)
            if (data?.configuration?.languages && this.route.snapshot.queryParams?.lang) { 
              this.user.setDefaultLanguage(data.configuration.languages, this.route.snapshot.queryParams.lang)
            }
            this.initConfig(isPatientTask)
          })
        }
        if (!isPatientTask) {
          this.featureFlagService.rxjsFeatureInitSubject.subscribe(_ => {
            this.featuresInit = true;
          })
        }
      }
    });
    const focus = (event) => {
      document['_activeElement'] = event.target
    }

    document.addEventListener('click', focus);
    document.addEventListener('focus', focus);
  }

  private getNpsRating() {
    if (!this.user.isLoggedIn() || this.user.isNpsModalDisplayed() || this.checkingNpsRating) {
      return;
    }
    this.checkingNpsRating = false
    this.initalCheckNpsCompleted = true;
    this.user.showNpsRatingControl().subscribe(data => {
      this.checkingNpsRating = false
      if (data.showRating) {
        this.showNpsRatingModal(data.options)
      }
    }, err => {
      this.checkingNpsRating = false
    })
  }

  private showNpsRatingModal(options) {
    this.npsOptions = options
    this.showNpsRating = true;
  }

  public closeNpsRating(data) {
    this.showNpsRating = false;
    this.user.storeNpsRating(data.action || 'SKIP', data.values || {}, this.npsOptions).subscribe(response => {
      console.log(response)
    })
  }

  private initConfig(isPatientTask) {
    if (!isPatientTask) {
      this.user.setMasters()
      this.user.getLanguages();
      this.loaded = true;
      console.log('npsRatingNoSubject')
      if (this.user.canViewOption('SHOWNPSRATINGINPXPORTAL') && !this.initalCheckNpsCompleted) {
        this.getNpsRating()
      }
      this.user.npsRatingSubject.subscribe(_ => {
        console.log('npsRatingSubject')
        if (this.user.canViewOption('SHOWNPSRATINGINPXPORTAL')) {
          this.getNpsRating()
        }
      })
    } else {
      this.user.getConfigurationInfo.subscribe(data => {
        if (data['profile'] && data['profile']['googleMapApiKey']) {
          let key = data['profile']['googleMapApiKey'];
          this.user.setGoogleApiKey(key);
        }
      });
      this.featuresInit = true;
      this.loaded = true;
    }
  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.gtm.addGtmToDom();
    this.user.getUserId().subscribe(userId => {
      const obj = {
        event_source: 'Website',
        language: this.user.currentLanguage,
        'user_id': userId
      }
      this.gtmService.pushGTMEvent(null, 'global_variables', obj);
    })
    let hash = window.location.hash;
    if(environment['COOKIESCRIPT'] && environment['COOKIESCRIPT'].enable && hash.includes('#/portal')){
      const lacaleBasedInfo=environment['COOKIESCRIPT']
      setTimeout(()=>{
        document.querySelector('html')?.setAttribute('lang', this.user.currentLanguage)
      },1500)
      const script = document.createElement('script');

      lacaleBasedInfo['directAttributes'].forEach(element => {
        script[element.key]=element.value
      });
      lacaleBasedInfo['setAttributes'].forEach(element => {
        script.setAttribute(element.key, element.value);
      });
      const parent_node = document.head || document.body;
      parent_node.appendChild(script);
      if (lacaleBasedInfo['onloadFunctions']) {
        script.addEventListener('load', () => {
          console.log('script load', window);
          lacaleBasedInfo['onloadFunctions'].forEach(element => { 
            switch (element) { 
              case 'setConsentBannerParams':
                if (lacaleBasedInfo['supportedDomain']) {
                  let mappedDomain = lacaleBasedInfo['supportedDomain'].reduce((acc, val) => {
                    acc[val] = this.user.currentLanguage
                    return acc
                  }, {});
                  (window as any).setConsentBannerParams({ overrideBannerLanguage: mappedDomain });
                }
                break;
              default:
                (window as any)[element]();
                break;
            }
          })
          
        });
      }
    }
  }


  ngAfterContentInit() {
    if(environment['enableHotJor']){
      ((h, o, t, j, a, r) => {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: environment['hotJorKey'], hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window as any, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
      console.log('hotjor init',environment['hotJorKey'])
    }

  }
  
}
