export const APP_VERSION = "8.1.13.0";

export const USER_LEFT = 'user-left';
export const START_CONVERSATION = 'start-conversation';
export const DOC_BUSY = 'doctor-busy';
export const INIT_VIDEO_CALL = 'init-video-call';
export const PATIENT_TASK_CHANGE = 'patient-task-change';
export const PATIENT_APPT_CHANGE = 'patient-appointment-change';
export const PATIENT_NOTIFICATION = 'notification';

//STORAGE
export const FILE_ACCESS_TOKEN = 'PX_FILE_ACCESS_TOKEN';
export const USER_TOKEN = 'PX_USER_TOKEN';
export const USER_OBJECT = 'PX_USER_OBJECT';
export const SSO_USER_OBJECT = 'PX_SSO_USER_OBJECT';
export const SSO_CREDENTIAL = 'PX_SSO_CREDENTIAL';
export const ACCESS_TOKEN = 'PX_ACCESS_TOKEN';
export const USER_LANGUAGE = "PX_USER_LANGUAGE";
export const REMAIND_ME = "PX_REMAIND_ME";
export const REPO_TOKEN = "PX_REPO_TOKEN";
export const TEMP_TOKEN = "PX_TEMP_TOKEN";
export const REPO_STATE = "PX_REPO_STATE";
export const EXPIRY_TIME = "PX_EXPIRY_TIME";
export const TASK_LIST = "PX_TASK_LIST";
export const CONTENT_HEADER = "PX_CONTENT_HEADER";
export const CONTENT_RESPONSE = "PX_CONTENT_RESPONSE";
export const HEALTHPKG_LIST = "PX_HEALTHPKG_LIST";
export const HEALTHPKG_REQ = "PX_HEALTHPKG_REQ";
export const PORTAL_SOURCE = "PXPORTAL";
export const PX_COUPONS = "PX_COUPONS";

export const DEFAULT_STORAGE_SETTINGS = [
  'FILE_ACCESS_TOKEN',
  'USER_TOKEN',
  'USER_OBJECT',
  'SSO_USER_OBJECT',
  'SSO_CREDENTIAL',
  'ACCESS_TOKEN',
  'USER_LANGUAGE',
  'REPO_TOKEN',
  'EXPIRY_TIME',
  'TASK_LIST',
  'HEALTHPKG_LIST',
  'HEALTHPKG_REQ'
] // Don't use REMAIND_ME; Add other storage objects if created; Use PX prefix for value;

export const PATH_MATCH_FULL = 'full';

const ICON_PATH = "assets/icons";
//ROUTES
export const ROUTE_LOGIN = 'login';
export const ROUTE_WEBVIEW = 'webview';
export let ROUTE_CRM = 'portal';
export let ROUTE_WEBACCESS = 'webaccess';
export const ROUTE_CRM_HOME = 'home';
export const ROUTE_CRM_CALENDAR = 'calendar';
export const ROUTE_CRM_TIMELINE = 'timeline';
export const ROUTE_CRM_HEALTH_RECORDS = 'health-records';
export const ROUTE_CRM_CARE_PLAN = 'care-plan';
export const ROUTE_CRM_PAYMENT = 'payment-checkout';
export const ROUTE_CRM_PATIENT_BILLING = 'patient-billing';
export const ROUTE_TASK = 'task';
export const ROUTE_VIEW = 'view';
export const ROUTE_APPT = 'appointment';
export const ROUTE_BILLPAY = 'billpay';
export const ROUTE_VACCINE = 'vaccine';
export const ROUTE_UNAUTHORIZED = 'page-unauthorized';
export const ROUTE_CRM_TASK = 'task';
export const ROUTE_VISIT = 'visitqr';
export const ROUTE_EMBED_APPT = 'embed-appt';

//ICONS
  //NAVIGATION
export const ICON_NAV_HOME = ICON_PATH + '/Home/home_selected.svg';
export const ICON_NAV_CALENDAR = ICON_PATH + '/Home/Appointments_selected.svg';
export const ICON_NAV_TIMELINE = ICON_PATH + '/navigation/timeline.svg';
export const ICON_NAV_HEALTH_RECORDS = ICON_PATH + '/Home/Healthrecords_selected.svg';
export const ICON_NAV_CARE_PLAN = ICON_PATH + '/navigation/care.svg';
export const ICON_NAV_PATIENT_BILLING = ICON_PATH + '/navigation/bills.svg';
export const ICON_NAV_HOME_INACTIVE = ICON_PATH + '/Home/home.svg';
export const ICON_NAV_CALENDAR_INACTIVE = ICON_PATH + '/Home/Appointments.svg';
export const ICON_NAV_TIMELINE_INACTIVE = ICON_PATH + '/navigation/timeline.svg';
export const ICON_NAV_HEALTH_RECORDS_INACTIVE = ICON_PATH + '/Home/Healthrecords.svg';
export const ICON_NAV_CARE_PLAN_INACTIVE = ICON_PATH + '/navigation/care.svg';
export const ICON_NAV_PATIENT_BILLING_INACTIVE = ICON_PATH + '/navigation/bills.svg';

//TITLE
  //NAVIGATION
export const TITLE_NAV_HOME = 'mnu_home';
export const TITLE_NAV_CALENDAR = 'mnu_calendar';
export const TITLE_NAV_TIMELINE = 'mnu_timeline';
export const TITLE_NAV_HEALTH_RECORDS = 'mnu_health_records';
export const TITLE_NAV_CARE_PLAN = 'mnu_careplan';
export const TITLE_NAV_PATIENT_BILLING = 'mnu_billing';

//CONTEXT

export const CONTEXT_HOME = 'CONTEXT_HOME';
export const CONTEXT_CALENDAR = 'CONTEXT_CALENDAR';
export const CONTEXT_TIMELINE = 'CONTEXT_HOME';
export const CONTEXT_HEALTH_RECORDS = 'CONTEXT_HOME';
export const CONTEXT_CARE_PLAN = 'CONTEXT_HOME';
export const CONTEXT_PATIENT_BILLING = 'CONTEXT_PATIENT_BILLING';

//ID
export const ID_HOME = 'ID_HOME';
export const ID_CALENDAR = 'ID_CALENDAR';
export const ID_TIMELINE = 'ID_HOME';
export const ID_HEALTH_RECORDS = 'ID_HOME';
export const ID_CARE_PLAN = 'ID_HOME';
export const ID_PATIENT_BILLING = 'ID_PATIENT_BILLING';

//TOAST
export const TOAST_ERROR = 'error';
export const TOAST_INFO = 'success';
export const TOAST_WARNING = 'info';
export const TOAST_SUCCESS = 'warning';

//API-PATH
export const API_CONTENT = 'api/content';
export const API_SECURITY = 'api/security';
export const API_APPOINTMENT = 'api/appointments';
export const API_PERSONS = 'api/persons';
export const API_EXTERNAL_AUTH = 'api/externalAuth';
export const API_UPLOADFILE = 'api/workflow/uploadImage';
export const API_SERVICES = 'api/services';
export const API_ACTIVITY = 'api/activity';
export const API_BILLING = 'api/billing';
export const API_OUTPATIENTS = 'api/outpatients';
export const API_COUPON = 'api/coupon';
export const API_WORKFLOW = 'api/workflow';

// moment formats
export const DATE_FORMAT = 'DD MM YYYY';
export const DATE_FORMAT_LONG = 'DD MMM YYYY';
export const DATE_FORMAT_SHORT = 'MMM YY';
export const TIME_FORMAT_24 = 'HH:mm';
export const TIME_FORMAT_12 = 'hh:mm a';
export const TIME_FORMAT_12_SHORT = 'h:mm a';
export const TIME_FORMAT_12_LONG = 'hh:mm:ss a';
export const DATE_ONLY_FORMAT = 'DD';
export const MONTH_FORMAT_SHORT = 'MM';
export const MONTH_FORMAT_LONG = 'MMM';
export const YEAR_FORMAT_SHORT = 'YY';
export const YEAR_FORMAT_LONG = 'YYYY';

export const APPT_LIST_MODIFIED = 'appointment-modified';

export const HC_PRACT_STARTED = 'PRACT_STARTED';

export const EXPRESS_CARE_TERMS_COND = 'EXPRESS-CARE-TERMS-COND';

export const SESSION_EXPIRED = 'SESSION-EXPIRED';